import {
  collection,
  query,
  orderBy,
  where,
  onSnapshot,
  getDocs,
  limit,
  startAfter,
} from 'firebase/firestore'
import { db } from '@/main'
import Resource from '@/data/tools/Resource'

export default class ThoughtDao{

  first: any
  last: any

  async getThoughts(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'thoughts'),
        where('isDeleted', '==', false),
        orderBy('created', 'desc'),
        limit(9)
      )
      onSnapshot(q, (snap) => {
        this.last = snap.docs[snap.docs.length - 1]
        callback(Resource.success(snap.docs.map((doc) => doc.data())))
      })
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getFirstThought(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'thoughts'),
        where('isDeleted', '==', false),
        orderBy('created', 'desc'),
        limit(1)
      )
      const snap = await getDocs(q)
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getLastThought(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'thoughts'),
        where('isDeleted', '==', false),
        orderBy('created', 'asc'),
        limit(1)
      )
      const snap = await getDocs(q)
      callback(Resource.success(snap.docs.map((doc) => doc.data())))
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getNextThoughts(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'thoughts'),
        where('isDeleted', '==', false),
        orderBy('created', 'desc'),
        startAfter(this.last),
        limit(9)
      )
      onSnapshot(q, (snap) => {
        this.first = snap.docs[0]
        this.last = snap.docs[snap.docs.length - 1]
        callback(Resource.success(snap.docs.map((doc) => doc.data())))
      })
    } catch (error) {
      callback(Resource.error(error))
    }
  }

  async getBackThoughts(callback: (resource: Resource) => any){
    callback(Resource.loading())
    try {
      const q = query(
        collection(db, 'thoughts'),
        where('isDeleted', '==', false),
        orderBy('created', 'asc'),
        startAfter(this.first),
        limit(9)
      )
      onSnapshot(q, (snap) => {
        this.last = snap.docs[0]
        this.first = snap.docs[snap.docs.length - 1]
        const result = snap.docs.reverse()
        callback(Resource.success(result.map((doc) => doc.data())))
      })
    } catch (error) {
      console.log('Este es el error: ', error)
      callback(Resource.error(error))
    }
  }
}